@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

html,
body {
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

